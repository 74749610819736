import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// import AuthCode from 'react-auth-code-input';
import classNames from 'classnames';
import HTTPService from '../../../Service/service'
import './auth.css'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Alert from '../../../components/bootstrap/Alert';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';

// eslint-disable-next-line react/prop-types
const LoginHeader = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { darkModeStatus } = useDarkMode();

	const [usernameInput, setUsernameInput] = useState(false);
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	const [userKey,setuserKey] = useState('')
	const [otp, setOtp] = useState('');
	const [phoneNumber,setPhoneNumber] = useState('')
	const [validateOTP, setValidateOTP] = useState('')
	const navigate = useNavigate();

	const handleChange =(e)=>{
		setValidateOTP('')
		setOtp(otp.concat(e.target.value))
		if(otp){
		}
	}
   
	const getOtp=()=>{
		HTTPService.getMobileOTP({
			"phone": phoneNumber,
			"countryCode": "+91"
		})
		.then(user=>{
			setuserKey(user.data.key)
		})
		.catch(error=>{ console.log(error);})
	}
	
	  const getPhoneNumber=(e)=>{
		setPhoneNumber(e.target.value)
	  }
	const handleLogin = useCallback(() => {
		let isMounted = true;
		console.log(typeof otp,"ptoooo");
		HTTPService.verifyMobileOTP({key:userKey,otp})
		.then(isLoggedIn=>{
			if(isMounted){
				if(isLoggedIn.data){
					localStorage.setItem('token',isLoggedIn.data.token)
					navigate('/')
					setOtp('')
				}
			}	
		})
		.catch(error=>{
			setOtp('')
			console.log(error.response.data.errors[0]);
			setOtp('')
			setValidateOTP(error.response.data.errors[0])
			console.log(error);

		})
		return () => { isMounted = false }
	},[navigate,userKey,otp]);

	const movetoNext = (e, id)=>{
		if(e.target.value.length >= e.target.maxLength){
			document.getElementById(id).focus(); 
		}
	}
	return (
		<PageWrapper
			title={isNewUser ? 'Sign Up' : 'Login'}
			// className={classNames({ 'bg-warning': !isNewUser, 'bg-info': !!isNewUser })}
			className='bg-warning'
			>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link style={{backgroundColor:"white",borderRadius:"5px"}}
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={300} height={100} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										// 'bg-lo10-dark': darkModeStatus,
									})}>
									<div className='row row-cols-1 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												// color={darkModeStatus ? 'light' : 'dark'}
												color='light'
												// isLight={!!isNewUser}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setUsernameInput(false);
													setIsNewUser(!isNewUser);
												}}>
												Login
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4'>
											<div >
												{!usernameInput ? (
													<FormGroup className='col-12'
														id='login-username'
														isFloating
														label='Your Email/Mobile'>
														<Input autoComplete='username' onChange={getPhoneNumber}/>
													</FormGroup>
												) : (
													<>
													<div className='otp'>
														<Input id="first" className='otpInput' value={otp.charAt(0)} size="1" maxLength="1" onKeyUp={(e)=>movetoNext(e,"second")} onChange={(e)=>handleChange(e)}/>
														<Input id="second" className='otpInput' value={otp.charAt(1)} size="1" maxLength="1" onKeyUp={(e)=>movetoNext(e,"third")} onChange={(e)=>handleChange(e)}/>
														<Input id="third" className='otpInput' value={otp.charAt(2)} size="1" maxLength="1" onKeyUp={(e)=>movetoNext(e,"fourth")} onChange={(e)=>handleChange(e)}/>
														<Input id="fourth"className='otpInput' value={otp.charAt(3)} size="1" maxLength="1" onChange={(e)=>handleChange(e)}/>
														{/* <Input id="5" className='otpInput' maxLength="1" />
														<Input id="6" className='otpInput' maxLength="1" onChange={(e,id)=>handleChange(e,id)} /> */}
													</div>
													{validateOTP && (<small style={{color:'red', marginLeft:'35%'}}>{validateOTP}</small>)}
													{/* {validateOTP && <Alert isLight color='info' className='flex-nowrap'>
								4 new components added.
							</Alert>} */}
													{/* <small></small> */}
													<div className='text-center h5 text-muted mb-3 mt-3'>Did not receive a code? <Button
														className='otpResendButton'
														onClick={getOtp}
														>
														Resend
													</Button></div>
													</>
												)}
											</div>
											<div className='col-12'>
												{!usernameInput ? (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={() => {setUsernameInput(true);
														                 getOtp();
																		 }}>
														Continue
													</Button>
												) : (
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={handleLogin}>
														Login
													</Button>
												)}
											</div>


									{/* BEGIN :: Social Login */}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='https://docplix.com/privacy-policy' target='_blank' rel="noopener noreferrer"
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Privacy policy
							</a>
							<a
								href='https://docplix.com/terms-conditions' target='_blank' rel="noopener noreferrer"
								className={classNames('link-light text-decoration-none',
								 {
									// 'link-light': isNewUser,
									'link-dark': !isNewUser,
								}
								)}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login
