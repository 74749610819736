import axios from 'axios';
import showNotification from '../components/extras/showNotification';
import Icon from '../components/icon/Icon';

const BASE_URL = process.env.REACT_APP_API_URL;
const TEMP_URL = 'http://43.204.46.88';

const instance = axios.create({
	baseURL: TEMP_URL,
});

instance.interceptors.request.use(function (config) {
	const token = localStorage.getItem('token');
	config.headers.Authorization = token ?? '';
	return config;
});

instance.interceptors.response.use(
	function (res) {
		return res;
	},
	function (error) {
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon='x-circle' size='lg' className='me-1' />
				<span>Error</span>
			</span>,
			error.response ? error.response.data.errors[0].message : error.message,
			'danger',
		);
		return Promise.reject(error);
	},
);

export default instance;
