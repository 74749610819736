// import axios from 'axios'
import axios from './axios';

//   instance.defaults.headers.common.header= sessionStorage.getItem('token')
// axios.interceptors.request.use(function (config) {
//     config.headers.Authorization = sessionStorage.getItem('token');
//     return config;
// });
// const token = sessionStorage.getItem('token')
// axios.defaults.headers.common.Authorization = `Bearer ${token}`
// const base_URL = process.env.REACT_APP_API_URL // 'https://stag.docplix.online/api'
// let instance = axios.create({
//     baseURL:base_URL
//   })
// instance.interceptors.request.use(function (config){
//     const token = sessionStorage.getItem('token')
//     config.headers.authorization = token || '';
//     return config
// })

// export instance
// Hard coded
// const  ='http://localhost:8000'

const HTTPService = {
	getMobileOTP: (uaername) => {
		return axios.post(`/doctor/getotp`, uaername);
	},
	verifyMobileOTP: (OTPWithKey) => {
		return axios.post(`/doctor/verify-otp`, OTPWithKey);
	},
	getDoctorProfile: () => {
		return axios.get(`/doctor/profile`);
	},
	getAminities: () => {
		return axios.get('/amenities');
	},
	addDepartment: (department) => {
		return axios.post('/department/', department);
	},
	getStateList: (code) => {
		return axios.get('/states?country_id=' + code);
	},
	getCityList: (code) => {
		return axios.get('/cities?state_id=' + code);
	},
	createNewOrganisation: (details) => {
		console.log(details);
		return axios.post('/organisation/', details);
	},
	getAllOrganisations: () => {
		return axios.get('/organisation');
	},
	deleteOrganisation: (id) => {
		return axios.delete('/organisation/' + id);
	},
	getDepartmentImages: () => {
		return axios.get('/department_images');
	},
	uploadDepartmentImages: (images) => {
		return axios.post('/department_images/', images);
	},
	getOrganisationbyID: (id) => {
		return axios.get('/organisation/' + id);
	},

	//Organisation_image

	getOrganisationImagesbyID: (id) => {
		return axios.get('/organisation_images/' + id);
	},
	uploadOrganisationImages: (images) => {
		return axios.post('/organisation_images/', images);
	},
	deleteOrganisationImageByID: (id) => {
		return axios.delete('/organisation_images/' + id);
	},
	updateOrganisationByID: (id, data) => {
		return axios.put('/organisation/' + id + '/', data);
	},
	partialUpdateOrganisationById: (id, data) => {
		return axios.patch('/organisation/' + id + '/', data);
	},
	getAllDepartment: () => {
		return axios.get('/department');
	},
	deleteDepartmentByID: (id) => {
		return axios.delete('/department/' + id);
	},
	getDepartmentByID: (id) => {
		return axios.get('/department/' + id);
	},
	updateDepartmentByID: (id, data) => {
		return axios.put('/department/' + id + '/', data);
	},
	deleteDepartmentImageByID: (id) => {
		return axios.delete('/department_images/' + id);
	},
	getAllCategories: () => {
		return axios.get('/categories');
	},
	addCategories: (details) => {
		return axios.post('/categories/', details);
	},
	getCatogoriesById: (id) => {
		return axios.get('/categories/' + id);
	},
	updateCategoriesById: (id, data) => {
		return axios.put('/categories/' + id + '/', data);
	},
	partialUpdateCategoriesById: (id, data) => {
		return axios.patch('/categories/' + id + '/', data);
	},
	deleteCategories: (id) => {
		return axios.delete('/Categories/' + id);
	},
	getCategoriesGalleryImgs: () => {
		return axios.get('/category_gallery_imgs');
	},
	addCategoriesGalleryImg: (img) => {
		return axios.post('/category_gallery_imgs/', img);
	},
	deleteCategoriesByID: (id) => {
		return axios.delete('/categories/' + id);
	},
	getCategoriesGalleryImgs: () => {
		return axios.get('/category_gallery_imgs');
	},
	getReference: () => {
		return axios.get('/references');
	},
	addReference: (ref) => {
		return axios.post('/references/', ref);
	},

	//Parameters-routes

	getAllParameters: () => {
		return axios.get('/parameters');
	},
	addParameters: (details) => {
		return axios.post('/parameters/', details);
	},
	getParametersById: (id) => {
		return axios.get('/parameters/' + id);
	},
	updateParametersById: (id, data) => {
		return axios.put('/parameters/' + id + '/', data);
	},
	partialUpdateParametersById: (id, data) => {
		return axios.patch('/parameters/' + id + '/', data);
	},
	deleteParametersByID: (id) => {
		return axios.delete('/parameters/' + id);
	},

	//Parameters-value-routes

	getAllParametersValue: () => {
		return axios.get('/parameters-values');
	},
	addParametersValue: (details) => {
		return axios.post('/parameters-values/', details);
	},
	getParametersValueById: (id) => {
		return axios.get('/parameters-values/' + id);
	},
	updateParametersValueById: (id, data) => {
		return axios.put('/parameters-values/' + id + '/', data);
	},
	partialUpdateParametersValueById: (id, data) => {
		return axios.patch('/parameters-values/' + id + '/', data);
	},
	deleteParametersValueByID: (id) => {
		return axios.delete('/parameters-values/' + id);
	},
};

export default HTTPService;
